<template>
  <div id="inspections"  class="pt-4 pb-4 ml-4 mr-4  border-top">
    <div  class="row align-items-center pb-4">
      <div class="col-5 ">
        <h3 class="mb-0">Site Visits / Sales</h3>
      </div>
      <div class="col-7 text-right">
        <el-dropdown split-button type="primary" size="small" @click="venueBooking" @command="handleDropDownCommand" class="mr-2">
          + Booking
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="customBooking">Custom Booking</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown split-button type="primary" class="site-visit-link" size="small" @click="gotToVenueShortList" @command="handleDropDownCommand" >
          + Site Visit
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="showCustomSiteVisitForm">Custom Inspection</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <venue-inspections :lead-event="leadEvent"></venue-inspections>
      </div>
    </div>
    <el-dialog
      title="Mark this venue as won?"
      width="400px"
      v-loading="loading"
      :visible.sync="showVenueStatusChangeWon"
      v-if="showVenueStatusChangeWon"
      :append-to-body="true"
    >
      <div class="row">
        <div class="col-lg-12">
          <status-win-form 
            :lead-event="leadEvent" 
            :event_date ="event_date"
            @submit="submitStatusUpdateRequest" 
            @cancel="showVenueStatusChangeWon=false" 
          />
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="Custom Booking"
      width="400px"
      v-loading="loading"
      :visible.sync="showCustomBookingForm"
      v-if="showCustomBookingForm"
      :append-to-body="true"
    >
      <div class="row">
        <div class="col-lg-12">
          <custom-booking 
            :lead-event="leadEvent" 
            :event_date ="event_date"
            @submit="submitStatusUpdateRequest" 
            @cancel="showCustomBookingForm=false" 
          />
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="Custom Site Visit"
      width="400px"
      v-loading="loading"
      :visible.sync="showCustomSiteVisitForm"
      v-if="showCustomSiteVisitForm"
      :append-to-body="true"
    >
      <div class="row">
        <div class="col-lg-12">
          <custom-site-visit 
            :lead-event="leadEvent" 
            @submit="submitStatusUpdateRequest" 
            @cancel="showCustomSiteVisitForm=false" 
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import VenueInspections from "@/views/Widgets/Inspections/VenueInspections";
import StatusWinForm from '@/views/Widgets/LostWon/StatusWinForm.vue';
import CustomBooking from '@/views/Widgets/LostWon/CustomBooking.vue';
import CustomSiteVisit from '@/views/Widgets/Inspections/CustomSiteVisit.vue';
import { Dropdown, DropdownMenu, DropdownItem } from 'element-ui';
export default {
  components: {
    VenueInspections,
    [StatusWinForm.name]: StatusWinForm,
    [CustomBooking.name]: CustomBooking,
    [CustomSiteVisit.name]: CustomSiteVisit,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
  },
  props: {
    leadEvent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      showVenueStatusChangeWon: false,
      showCustomBookingForm: false,
      showCustomSiteVisitForm: false,
    };
  },
  methods: {
    gotToVenueShortList() {
      this.$router.push({ name: "LeadInspections", params: { id: this.leadEvent.lead_id, eventId: this.leadEvent.id } });
    },
    venueBooking() {
      this.event_date = this.leadEvent.event_start;
      this.showVenueStatusChangeWon = true;
    },
    showCustomBooking() {
      this.event_date = this.leadEvent.event_start;
      this.showCustomBookingForm = true
    },
    handleDropDownCommand(command) {
      if (command == 'customBooking') {
        this.showCustomBooking();
      } else if (command == 'showCustomSiteVisitForm') {
        this.showCustomSiteVisitForm = true;
      }
    },
    async submitStatusUpdateRequest(data) {
      let self = this;
      await self.$store.dispatch(
        "inspections/getInspections",
        self.leadEvent.id
      );
      await this.$store.dispatch(
        "inspections/getSalesWithoutInspections",
        self.leadEvent.id
      ); 
      await this.$store.dispatch(
        "inspections/getCustomVenueInspections",
        self.leadEvent.id
      ); 
      this.showVenueStatusChangeWon = false;
      this.showCustomBookingForm = false;
      this.showCustomSiteVisitForm =false;
    },
  },
};
</script>
<style>
#inspections .el-button--primary {
  background-color: #1171ef;
  border-color: #1171ef;
}
</style>