<template>
  <div class="mt-2">
    <h5 class="mb-0">
      <i class="fas fa-map-marker-alt"></i> {{ venue_name }} <badge type="primary" rounded> Custom Venue </badge>
    </h5>
    <badge
      v-if="inspection_date && inspection_time"
      rounded
      :type="inspectionStatusBadgeType(inspection_status)"
    >
      <i class="fas fa-calendar-alt"></i>
      {{ inspection_date | moment("dddd, MMMM Do YYYY") }}
      <span v-if="inspection_status == InspectionService.INSPECTION_STATUS_CONFIRMED">@ {{ convertedTime }}</span>
      <span v-else-if="inspection_status == InspectionService.INSPECTION_STATUS_REQUESTED">
        <span>@ {{ convertedTime }} -</span>
        {{ inspection_status | removeUnderscore}}
      </span>
      <span v-else>
        <span>-</span>
        {{ inspection_status | removeUnderscore}} 
      </span>
    </badge>
    <badge
      v-if="! (inspection_date && inspection_time) && sale_status"
      rounded
      type="info"
    >No Inspection - Custom Booking</badge>
    <badge v-if="sale_status" rounded :type="saleStatusBadgeType(sale_status)" class="ml-2">
      <span>
        Sale
      </span>
      <span v-if="isSaleWon(sale_status)">
        : #{{sale_id}}
      </span>
    </badge>
  </div>
</template>
<script>
import InspectionService from "@/services/inspection.service";
import SaleService from "@/services/saleService";
import moment from 'moment-timezone';

export default {
  name: "custom-venue-inspection-lists",
  data() {
    return {
      InspectionService,
    };
  },
  props: {
    leadEvent: {
      type: Object,
      required: true,
    },
    venue_name: {
      type: String,
      required: true,
    },
    inspection_date: {
      type: String,
      required: false,
    },
    inspection_time: {
      type: String,
      required: false,
    },
    timezone: {
      type: String,
      required: false,
    },
    inspection_status: {
      type: String,
      required: false,
    },
    sale_status: {
      type: String,
      required: false,
    },
    sale_date: {
      type: String,
      required: false,
    },
    sale_id: {
      type: Number,
      required: false,
    },
    custom_venue_id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    convertedTime() {
      if (!this.inspection_time || !this.timezone) return this.inspection_time;
      
      // Create a moment object with the inspection date and time in the venue's timezone
      const dateTimeStr = `${this.inspection_date} ${this.inspection_time}`;
      const venueDateTime = moment.tz(dateTimeStr, 'YYYY-MM-DD h:mm a', this.timezone);
      
      // Convert to user's local timezone
      const localDateTime = venueDateTime.local();
      
      // Return formatted time
      return localDateTime.format('h:mma');
    },
    saleStatusBadgeType() {
      return function (sale_status) {
        let badge = 'secondary';
        switch (sale_status && sale_status.toLowerCase()) {
        case "lost":
        case "cancelled":
          badge = 'warning';
          break;
        case "won": 
          badge = "success";
          break;
        default:
          break;
        }
        return badge;
      };
    },
    inspectionStatusBadgeType() {
      return function (inspection_status) {
        let badge = 'info';
        switch (inspection_status && inspection_status.toLowerCase()) {
        case InspectionService.INSPECTION_STATUS_REQUESTED:
          badge = 'yellow';
          break;
        case InspectionService.INSPECTION_STATUS_RESCHEDULED:
          badge = 'pink';
          break;
        case InspectionService.INSPECTION_STATUS_CONFIRMED:
          badge = 'info';
          break;
        case InspectionService.INSPECTION_STATUS_ATTENDED: 
          badge = "success";
          break;
        case InspectionService.INSPECTION_STATUS_NOT_ATTENDED: 
          badge = "warning";
          break;
        case InspectionService.INSPECTION_STATUS_CANCELLED: 
          badge = "danger";
          break;
        default:
          break;
        }
        return badge;
      };
    },
    isSaleWon() {
      return function (sale_status) {
        if(sale_status.toLowerCase() === SaleService.SALE_STATUS_WON) {
          return 1;
        }
        return 0;
      };
    },
  },
  filters: {
    removeUnderscore (str) {
      return str.replace(/_/g, " ");
    },
  },
};
</script>

<style lang="scss" scoped>
.inspection-item .inspection-item-controls {
  display: none;
}
.inspection-item:hover .inspection-item-controls {
  display: inline-block;
}
.inspection-item .badge-yellow {
	color: #bd8f05;
	background-color: #fbf8b9;
}
.inspection-item .badge-pink {
	color: #AC001E;
	background-color: #FF647F8A;
}
</style>
