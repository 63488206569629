<template>
  <el-form
    class="karen-status-change-request status-win-form-container"
    :inline="true"
    :model="formData"
    ref="karen-status-change-request"
    v-loading="loading"
  >
    <div>
      <base-input label="Venue Name" :visible.sync="show_select_venue_text" v-if="show_select_venue_text">
        <el-autocomplete
          id="select_venue"
          v-model="select_venue_id"
          value-key="name"
          :fetch-suggestions="querySearch"
          placeholder="Select Venue"
          @select="handleSelect"
          @input="handleInput"
        >
          <template slot-scope="{ item }">
            <div>{{ item.name }}</div>
          </template>
        </el-autocomplete>
        <span :visible.sync="empty_venue_message" v-if="empty_venue_message" class="error-message">Please select a venue.</span>
      </base-input>
      <base-input label="Sale Value" >
        <el-input
          placeholder="Please input"
          @keydown.native="allowNumericOnly"
          v-model="formData.sale_value">
          <template slot="prepend">{{currency}}</template>
        </el-input>
      </base-input>
      <base-input label="Commission" >
        <el-input
          placeholder="Please input"
          @keydown.native="allowNumericOnly"
          v-model="formData.commission">
          <template slot="prepend">{{currency}}</template>
        </el-input>
      </base-input>
      <base-input label="Sale Date" >
        <el-date-picker
          v-model="formData.sale_date"
          type="date"
          placeholder="Pick a Date"
          format="dd/MM/yyyy"
          value-format="yyyy-MM-dd"
        />
      </base-input>
      <base-input label="Event Date" >
        <el-date-picker
          v-model="formData.event_date"
          type="date"
          placeholder="Pick a Date"
          format="dd/MM/yyyy"
          value-format="yyyy-MM-dd"
        />
      </base-input>

    </div>
    <div class="text-right">
      <base-button
        type="secondary"
        native-type="button"
        id="submit_karen_status_change_request"
        class="mt-4"
        @click="cancel()"
      >
        Cancel
      </base-button>
      <base-button
        type="primary"
        native-type="button"
        id="submit_karen_status_change_request"
        class="mt-4"
        :disabled="disableSubmitButtonIfValidationFails"
        @click.stop="submitWon()"
      >
        Confirm
      </base-button>
    </div>
    <el-dialog
      title="Custom Booking"
      width="400px"
      v-loading="loading"
      :visible.sync="showCustomBookingForm"
      v-if="showCustomBookingForm"
      :append-to-body="true"
    >
      <div class="row">
        <div class="col-lg-12">
          <custom-booking 
            :lead-event="leadEvent" 
            :event_date ="event_date"
            :initial-venue-name="enteredVenueName"
            @submit="submitCustomVenueBooking" 
            @cancel="showCustomBookingForm=false" 
          />
        </div>
      </div>
    </el-dialog>
  </el-form>
</template>
<script>
import {
  FormItem,
  Form,
  Input,
  Autocomplete,
  DatePicker
} from "element-ui";
import { VenueAuditLog, VenueService } from "@/services";
import CustomBooking from "@/views/Widgets/LostWon/CustomBooking.vue";
export default {
  name: "status-win-form",
  components: {
    [FormItem.name]: FormItem,
    [Form.name]: Form,
    [Input.name]: Input,
    [Autocomplete.name]: Autocomplete,
    [DatePicker.name] : DatePicker,
    CustomBooking
  },
  props: {
    leadEvent: {
      type: Object,
      required: true,
    },
    venue_id: {
      required: false,
    },
    sale_value: {
      required: false,
    },
    commission: {
      required: false,
    },
    sale_date: {
      required: false,
    },
    event_date: {
      required: false,
    },
  },
  data: function() {
    return {
      formData: {
        status: "won",
        lead_event_id: this.leadEvent.id,
        sale_value: this.sale_value,
        commission: this.commission,
        sale_value_currency: this.currency,
        venue_id: this.venue_id,
        sale_date : this.sale_date,
        event_date: this.event_date,
      },
      venues: [],
      show_select_venue_text: false,
      empty_venue_message : false,
      select_venue_id : this.venue_id,
      loading: false,
      selected_venue: '',
      showCustomBookingForm: false,
      enteredVenueName: ''
    };
  },
  computed: {
    currency: function() {
      if (this.selected_venue && this.selected_venue.country && this.selected_venue.country.toLowerCase() === 'uk') {
        return '£';
      } else {
        return '$';
      }
    },
    disableSubmitButtonIfValidationFails() {
      if (! parseFloat(this.formData.sale_value)) {
        return true;
      }

      if (! parseFloat(this.formData.commission)) {
        return true;
      }

      if (! this.formData.sale_date) {
        return true;
      }

      return false;
    },
  },
  beforeMount() {
    if(!this.venue_id) {
      this.show_select_venue_text = true;
    } 
  },
  methods: {
    submitWon() {
      if(!this.formData.venue_id) {
        this.empty_venue_message = true;
        this.select_venue_id = "";
        return false;
      }
      let self = this;
      this.loading = true;
      self.formData.sale_value_currency = self.currency;
      VenueService.changeLeadSaleStatus(
        this.formData.venue_id,
        this.leadEvent.id,
        this.formData
      ).then(async () => {
        this.loading = false;
        this.$emit('submit', this.formData);
        this.resetForm();
      }).catch((error) => {
        this.loading = false;
        this.$store.dispatch("alert/error", error, { root: true });
      });
    },
    resetForm() {
      this.formData.venue_id = "";
      this.formData.sale_value = "";
      this.formData.commission = "";
      this.enteredVenueName = "";
    },
    submitCustomVenueBooking() {
      this.$emit('submit');
    },
    cancel() {
      this.$emit('cancel');
    },
    querySearch(queryString, cb) {
      var venues = this.venues;
      var results = queryString ? venues.filter(this.createFilter(queryString)) : venues;


      // If there are no matches and we have a query string, add the "Create New Venue" option
      if (queryString && results.length === 0) {
        results = [{
          name: `Create new custom booking: "${queryString}"`,
          isCreateNewCustomBooking: true,
          originalName: queryString
        }];
      }

      // call callback function to return suggestions
      cb(results);
    },
    createFilter(queryString) {
      return (venue) => {
        return (venue.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item) {
      if (item.isCreateNewCustomBooking) {
        this.enteredVenueName = item.originalName;
        this.select_venue_id = "";
        this.formData.venue_id = "";
        this.showCustomBookingForm = true;
        return;
      }

      this.formData.venue_id = item.id;
      this.empty_venue_message = false;
      this.loadSaleDataIfNotGiven();
    },
    
    handleInput(value) {
      this.enteredVenueName = value;
    },

    async loadSaleDataIfNotGiven() {
      if (this.sale_value && this.commission && this.sale_date && this.event_date) {
        return;
      }
      this.loading = true;
      let venueId = this.venue_id ? this.venue_id : this.formData.venue_id;
      let data = await VenueAuditLog.getVenueSaleData(venueId, this.leadEvent.id);
      this.loading = false;
      if (data) {
        this.formData.sale_value = data.sale_value;
        this.formData.commission = data.commission;
        this.formData.sale_date = data.sale_date;
        this.formData.event_date = data.event_date;
      }
    },

    allowNumericOnly(event) {
    // Allow key codes for special keys: backspace, delete, left and right arrows, and escape
      const specialKeys = [
        'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Escape',
      ];

      // Check if the pressed key is a special key
      if (specialKeys.includes(event.key)) {
        return;
      }

      // Get the character from the event
      let char = String.fromCharCode(event.keyCode);

      // Regex to check if the character is a number
      const pattern = /[0-9]/;

      // Check if the character is not a number or dot
      if (! (pattern.test(char) || event.keyCode === 190)) {
        event.preventDefault(); // Prevent invalid character input
      }
    },
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch("venues/getAll", { lead_id: this.leadEvent.lead_id });
    this.loading = false;
    this.venues = this.$store.state.venues.items;
    if (this.venue_id) {
      this.selected_venue = this.$store.getters["venues/getVenueById"](this.venue_id);
      this.loadSaleDataIfNotGiven();
    }
  },
  watch: {
    'formData.venue_id': {
      handler: function() {
        this.selected_venue = this.$store.getters["venues/getVenueById"](this.formData.venue_id);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style>
.status-win-form-container .el-autocomplete{
  width:100%;
}
.status-win-form-container .el-date-editor{
  width:100%;
}
.status-win-form-container .error-message{
  color:#ff0000;
  font-size: 13px;
}
</style>